var formEmailSubscription = {

    working: false,

    config: {
        button: '.js__subscribe-newsletter-button',
        input: '.js__subscribe-newsletter-input'
    },

    init: function(){
        var _self = this;

        var button = document.querySelector(this.config.button);
        if(button){

            button.addEventListener('click',function(){
                _self.submit();
            });

            button.addEventListener('touchstart',function(){
                _self.submit();
            });

        }
    },

    submit: function(){
        var _self = this;
        var input = document.querySelector(this.config.input);

        if(input && input.value.length > 0){
            if(this.validateEmail(input.value) === false){

                _self.showModalWindowResponse('<i class="pe-7s-attention modal-content__subscribe-newsletter-form--icon"></i><br>' + translate.getTranslate('FORM_EMAIL_SUBSCRIPTION_EMAL_VALIDATION_FAILED'));

            } else {

                var content = '<i class="pe-7s-attention modal-content__subscribe-newsletter-form--icon"></i><br>' + translate.getTranslate('FORM_EMAIL_SUBSCRIPTION_ADDING_EMAIL_FAILED');
                this.working = true;

                modalWindow.showWithLoading();

                aja()
                    .method('post')
                    .url('/emailreceivers/add')
                    .type('json')
                    .data({
                        'ajax': 1,
                        'email': input.value
                    })
                    .on('success', function (data) {

                        if(data){

                            input.value = '';
                            content = '<i class="pe-7s-check modal-content__subscribe-newsletter-form--icon green"></i><br>' + translate.getTranslate('FORM_EMAIL_SUBSCRIPTION_EMAIL_WAS_ADDED');

                        }

                        _self.showModalWindowResponse(content);
                        _self.working = false;

                    })
                    .on('40x', function(){

                        _self.showModalWindowResponse(content);
                        _self.working = false;
                    })
                    .on('500', function(){

                        _self.showModalWindowResponse(content);
                        _self.working = false;
                    })
                    .go();
            }
        }
    },

    showModalWindowResponse: function(content) {

        modalWindow.show('<div class="modal-content__subscribe-newsletter-form">' + '<p class="modal-content__subscribe-newsletter-form--text">' + content + '</p>' + '<p><a href="javascript:void(0)" class="base-button-action-colored" onclick="modalWindow.close();" ontouchstart="modalWindow.close();">' + translate.getTranslate('I_UNDERSTAND') + '</a></p></div>', translate.getTranslate('FORM_EMAIL_SUBSCRIPTION_HEADLINE'));

    },

    validateEmail: function(email = ''){

        if(!email||email.length == 0){
            return false;
        }

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            return true;
        }

        return false;
    }

};