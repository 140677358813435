var modalWindow = {

    elements: {
        window: 'js__modalWindow',
        container: 'js__modalWindowContainer',
        headline: 'js__modalWindowHeadline',
        close: 'js__modalWindowClose',
        content: 'js__modalWindowContent',
        loading: 'js__ajax-loading-content',
        headlineContainer: 'js__modalWindowHeadlineContainer',
        confirmCloseButton: 'js__modalWindowConfirmCloseButton',
        customCloseButton: 'js__modalWindowCustomCloseButton'
    },

    modal: false,

    className: {
        body: 'modal-is-open',
        innerLoadingContainer: 'inner-loading',
        confirmAndCloseContainer: 'confirm-and-close-text'
    },

    init: function(){
        this.modal = document.getElementById(this.elements.window);
        if(this.modal){
            this.initCloseButton();
        }
    },

    initCloseButton: function(){
        var _self = this;
        var closeButton = document.getElementById(this.elements.close);
        if(closeButton){
            closeButton.addEventListener('click', function(){
                _self.close();
            });
        }
    },

    getContentElement: function(){
        return document.getElementById(this.elements.content);
    },

    getContentID: function(){
        return this.elements.content;
    },

    changeHeadline: function(content, showHideHeadline = true){
        this.updateElement(this.elements.headline, content);
        this.showHideHeadline(showHideHeadline);
    },

    changeContent: function(content){
        this.updateElement(this.elements.content, content);
    },

    show: function(content, headline = '', showHideHeadline = true) {
        this.updateElement(this.elements.headline, headline);
        this.showHideHeadline(showHideHeadline);
        this.updateElement(this.elements.content, content);
        var body = this.getBodyElement();
        if(body){
            addClass(body, this.className.body);
        }
        this.modal.style.display = 'block';
    },

    showHideHeadline: function(show){
        var element = document.getElementById(this.elements.headlineContainer);
        if(element){
            element.style.display = show?'block':'';
        }
    },

    updateElement: function(elementID, content = ''){
        var element = document.getElementById(elementID);
        if(element){
            element.innerHTML = content;
            //try to find close button
            let listCloseButtons = element.querySelectorAll('.' + this.elements.customCloseButton);
            for(var i=0; i < listCloseButtons.length; i++){
                listCloseButtons[i].addEventListener('click', function(){
                    modalWindow.close();
                });
            }
        }
    },

    close: function() {
        this.modal.style.display = '';
        var body = this.getBodyElement();
        if(body){
            removeClass(body, this.className.body);
        }
    },

    getBodyElement: function(){
        return document.getElementsByTagName("body")[0];
    },

    showWithLoading: function(){
        this.show(this.getLoadingHtml(), '', false);
    },

    getLoadingHtml: function(){
        var el = document.getElementById(this.elements.loading);
        return el?el.innerHTML:'';
    },

    removeInnerLoading: function(){
        var contentContainer = document.getElementById(this.elements.content);
        if(contentContainer){
            var innerLoadingContainer = contentContainer.querySelector('.' + this.className.innerLoadingContainer);
            if(innerLoadingContainer){
                innerLoadingContainer.remove();
            }
        }
    },

    showInnerLoading: function(){
        var contentContainer = document.getElementById(this.elements.content);
        if(contentContainer){
            var loadingElement = document.getElementById(this.elements.loading);
            if(loadingElement){
                var innerLoadingContainer = document.createElement('div');
                innerLoadingContainer.className = this.className.innerLoadingContainer;
                innerLoadingContainer.innerHTML = loadingElement.innerHTML;
                contentContainer.append(innerLoadingContainer);
            }
        }
    },

    showIUnderstandConfirm: function(message){
        var _self = this;
        this.changeContent('<div class="' + this.className.confirmAndCloseContainer + '">' + message + '<br>' + '<button class="default-button-style" id="' + this.elements.confirmCloseButton + '">' + translate.getTranslate('I_UNDERSTAND') + '</button></div>');
        var confirmButton = document.getElementById(this.elements.confirmCloseButton);
        confirmButton.addEventListener('click', function(){
            _self.close();
        });
    }

};