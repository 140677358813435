class EshopCurrency {
    constructor(currency, decimals, decimalSeparator, isCurrencyBeforePrice, thousandsSeparator)
    {
        this.currency = currency;
        this.decimals = decimals;
        this.isCurrencyBeforePrice = isCurrencyBeforePrice;
        this.thousandsSeparator = thousandsSeparator;
        this.decimalSeparator = decimalSeparator;
    }
    getFormatedValue(value)
    {
        return (
            (this.isCurrencyBeforePrice === true?this.currency + ' ':'') +
            this.number_format(value, this.decimals, this.decimalSeparator, this.thousandsSeparator) +
            (this.isCurrencyBeforePrice === false?' ' + this.currency:'')
        );
    }
    number_format(number, decimals, dec_point, thousands_sep) //https://stackoverflow.com/a/34141813
    {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }
}