//=require ../../external/underscorejs/underscore-umd-min.js
//=require ../../external/baguetteBox/js/baguetteBox.js
//=require ../../external/aja.js/aja.min.js
//=require ../../external/splide/js/splide-2.4.10.min.js
//=require ../../external/nouislider/js/nouislider.min.js
//=require front/functions.js
//=require front/modal-window.js
//=require front/form-list-of-url.js
//=require front/form-range-slider.js
//=require front/form-drop-down.js
//=require front/open-by-click.js
//=require front/menu.js
//=require front/form-builder.js
//=require front/gallery-show-specific-number-images.js
//=require front/button-go-up.js
//=require general/form-click-protection.js
//=require front/eshop/small-cart.js
//=require front/eshop/currency.js
//=require front/eshop/menu.js
//=require front/form-email-subscription.js
//=require front/form-watch-dog.js

var template = new FrontTemplates();

baguetteBox.run('.js__baguettebox');

modalWindow.init();

formListOfUrl.init();

formRangeSlider.init();

formDropDown.init();

openByClick.init();

menu.initOpemClose();
menu.initDropDownClickTouch();

formBuilder.init();

if(document.querySelector('.js__splide')) {
    new Splide('.js__splide', {
        'type': 'loop',
        'autoplay': true,
        'interval': 5000,
        'arrows': false
    }).mount();
}

galleryShowSpecificNumberImages.init();

// buttonGoUp.init();
//
// window.onscroll = function() {
//     topNavBarScroll();
// };
// var header = document.getElementById("topMenuScroll");
// var sticky = header.offsetTop;
// function topNavBarScroll() {
//     if (window.pageYOffset > sticky) {
//         header.classList.add("viva_top-menu--fixed");
//     } else {
//         header.classList.remove("viva_top-menu--fixed");
//     }
// }

function shareOnfacebook(){
    var u = location.href;
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(u), 'FacebookShare', 'toolbar=0,status=0,width=626,height=436');
    return false;
}

function shareOnTweeter(t) {
    var u = location.href;
    window.open('http://twitter.com/share?url=' + encodeURIComponent(u) + '&text=' + encodeURIComponent(t), 'TwitterShare', 'toolbar=0,status=0,width=626,height=436');
    return false;
}

formEmailSubscription.init();

formWatchDog.init();

formClickProtection.init();

const
    searchboxFullscreenButton = document.querySelector('.js__searchbox-fullscreen--button'),
    searchboxFullscreen = document.querySelector('.js__searchbox-fullscreen'),
    searchboxFullscreenInput = document.querySelector('.js__searchbox-fullscreen--input'),
    searchboxFullscreenCloseButton = document.querySelector('.js__searchbox-fullscreen--close');

if(searchboxFullscreenButton && searchboxFullscreen && searchboxFullscreenInput && searchboxFullscreenCloseButton) {

    searchboxFullscreenCloseButton.addEventListener('click', function(e){

        e.preventDefault();
        searchboxFullscreen.classList.remove('open');

    });

    searchboxFullscreenButton.addEventListener('click', (e) => {

        e.preventDefault();
        searchboxFullscreen.classList.add('open');
        searchboxFullscreenInput.focus();

    });

}

const smallCart = new SmallCart(document.querySelector('.js__small-cart'));
// smallCart.refreshCount();

const eshopCurrency = new EshopCurrency(_arrCurrencySettings.currencySymbol, _arrCurrencySettings.decimals, _arrCurrencySettings.decimalSeparator, _arrCurrencySettings.isCurrencyBeforePrice, _arrCurrencySettings.thousandsSeparator);

const eshopMenu = new EshopMenu(document.querySelector('.js__eshop-menu'), document.querySelector('.js__eshop-mobile-menu-button'));

//=require ../../external/cookieconsent/js/cookieconsent.js

const fullscreenBanner = () => {

    try {

        let
            cookieName = 'fullscreenbanner',
            container = document.querySelector('.js__fullscreen-banner'),
            button = document.querySelector('.js__fullscreen-banner--close-button');

        if(button) {
            button.addEventListener('click', () => {
                container.style.display = 'none';
                createCookie(cookieName, '1', 365);
            })
        }

        if(container) {
            var existCookie = readCookie(cookieName);
            if(!existCookie){
                container.style.display = '';
            }
        }


    } catch (e) {
        console.log('[FullScreen banner error] ' + e);
    }

}

// fullscreenBanner();