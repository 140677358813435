var buttonGoUp = {

    config: {
        fromTop: 30
    },

    element: false,

    elements: {
        button: '.js__button-go-up'
    },

    init: function(){
        this.element = document.querySelector(this.elements.button);
        if(this.element) {
            this.element.addEventListener('click', function () {
                buttonGoUp.scrollToTop();
            });
            this.config.fromTop = getDocHeight() / 2;
            window.addEventListener('scroll', function () {
                if (window.pageYOffset > buttonGoUp.config.fromTop) {
                    buttonGoUp.element.style.display = 'flex';
                } else {
                    buttonGoUp.element.style.display = '';
                }
            });
        }
    },

    scrollToTop: function(){
        var rootElement = document.documentElement;
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

};