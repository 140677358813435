class EshopMenu {
    constructor(menu, button)
    {
        this.menu = menu;
        menu.classList.add('paging-mobile-menu__container');
        this.button = button;
        this.menu._this = this;
        this.button._this = this;
        this.button.addEventListener('click', this.toggleMenu);
        this.overlay = document.createElement("div");
        this.overlay.classList.add('paging-mobile-menu__overlay');
        insertBefore(document.body, this.overlay);
        let listElements = this.menu.querySelectorAll('.js__eshop-mobile-menu-parent, .js__eshop-mobile-menu-back');
        for(let i = 0; i < listElements.length; i++){
            listElements[i]._this = this;
            listElements[i].addEventListener('click', function(e){
                if(this._this.isSeenToggleButton() === false){
                    return true;
                }
                e.preventDefault();
                this._this.activateGroup(this.getAttribute('data-group'))
                return false;
            });
        }
        let firstGroup = this.menu.children[0];
        this.menu.firstDataGroup = firstGroup.getAttribute('data-group');
        this.activateGroup(this.menu.firstDataGroup);
    }
    toggleMenu()
    {
        if(this._this.menu.classList.contains('mobile-open')){
            this._this.menu.classList.remove('mobile-open');
            this._this.button.classList.remove('open');
            this._this.overlay.classList.remove('show');
        }else{
            this._this.menu.classList.add('mobile-open');
            this._this.button.classList.add('open');
            this._this.overlay.classList.add('show');
            this._this.activateGroup(this._this.menu.firstDataGroup);
        }
    }
    activateGroup(groupClass)
    {
        this.setAllHide();
        let menuGroup = this.menu.querySelector('.' + groupClass);
        if(menuGroup){
            menuGroup.classList.remove('paging-mobile-menu__hide-element');
            let listChildren = menuGroup.children;
            for(let i = 0; i < listChildren.length; i++){
                listChildren[i].classList.remove('paging-mobile-menu__hide-element');
                let listChildrenChildren = listChildren[i].children;
                for(let y = 0; y < listChildrenChildren.length; y++) {
                    listChildrenChildren[y].classList.remove('paging-mobile-menu__hide-element');
                }
            }
            //try to find all parents like ul or li tags
            this.showParent(menuGroup.parentNode);
        }
    }
    showParent(element)
    {
        if(element.classList.contains('paging-mobile-menu__container')){
            return;
        }
        element.classList.remove('paging-mobile-menu__hide-element');
        this.showParent(element.parentNode);
    }
    setAllHide()
    {
        let listElements = this.menu.querySelectorAll('ul, li, a');
        for(let i = 0; i < listElements.length; i++){
            listElements[i].classList.add('paging-mobile-menu__hide-element');
        }
    }
    isSeenToggleButton()
    {
        return window.getComputedStyle(this.button, null).display !== 'none';
    }
}