class SmallCart {
    constructor(element)
    {
        this.element = element;
        this.element._this = this;
    }
    updateCart(response)
    {
        this.element.innerHTML = parseInt(response);
    }
    refreshCount()
    {
        let _self = this.element;
        let requestData = {
            'ajax': 1
        };
        aja()
            .method('post')
            .url(_appUrl + 'getcountforsmallcart')
            .type('json')
            .data(requestData)
            .on('success', function (response) {
                _self._this.updateCart(response);
            })
            .go();
    }
}